import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery"

const Projects = () => (
  <Layout>
    <SEO title="HaroldRichter.art" />
    <Gallery></Gallery>
  </Layout>
)

export default Projects
