import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import '../styles/gallery.scss'

function getLink(index) {
  switch (index) {
    case 0:
      return <a className="gumroad-button" href="https://gum.co/krLWh?wanted=true" target="_blank">Buy #3, #4 Now</a>;
    case 1:
      return <a className="gumroad-button" href="https://gum.co/HEIDDP?wanted=true" target="_blank">Buy #2, #3, #4 Now</a>;
    case 2:
      return <a className="gumroad-button" href="https://gum.co/xDuWH?wanted=true" target="_blank">Buy ALL 4 Now</a>;
  }
  return null;
}

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Bundles = () => {
    const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: ASC }
        filter:{ relativePath: {regex: "/maddog/bundles/"}, extension:{regex:"/(jpeg)/"},  sourceInstanceName:{eq:"images"}}
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

    return (
        <div className="bundles">
            <h2>Bundles</h2>
            <div className="image-gallery">
            {allFile.edges.map((node, index) => {
                return (
                <div className="comic-link" key={index}>
                    {getLink(index)}
                    <Img
                        fluid={node.node.childImageSharp.fluid}
                    />
                </div>
                )
            })}
            </div>
        </div>
    )
}

export default Bundles;
