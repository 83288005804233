import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Bundles from "./bundles"

import '../styles/gallery.scss'

function getLink(index) {
  switch (index) {
    case 0:
      return <a className="gumroad-button" href="https://gum.co/eSCyp?wanted=true" target="_blank">Buy #1 Now</a>;
    case 1:
      return <a className="gumroad-button" href="https://gum.co/DcZyY?wanted=true" target="_blank">Buy #2 Now</a>;
    case 2:
      return <a className="gumroad-button" href="https://gum.co/AwUSvR?wanted=true" target="_blank">Buy #3 Now</a>;
    case 3:
      return <a className="gumroad-button" href="https://gum.co/eFvvT?wanted=true" target="_blank">Buy #4 Now</a>;
  }
  return null;
}

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Gallery = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: absolutePath, order: ASC }
        filter:{ relativePath: {regex: "/maddog/issue[0-9]/01/"}, extension:{regex:"/(jpeg)/"},  sourceInstanceName:{eq:"images"}}
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="gallery">
      <h1>Mad Dog's Mission</h1>
      <div className="image-gallery">
        {allFile.edges.map((node, index) => {
          return (
            <div className="comic-link" key={index}>
              {getLink(index)}
              <Link to={`/comics/maddog${index+1}`} key={node.node.id}>
                <Img
                    fluid={node.node.childImageSharp.fluid}
                    alt={`Mad Dog's Mission #${index+1}`}
                />
              </Link>
            </div>
          )
        })}
      </div>
      
      <Bundles></Bundles>
    </div>
  )
}

export default Gallery;
